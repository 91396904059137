import React, { useEffect, useState, useMemo, useReducer, useRef } from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import { FormControl, InputLabel, Input, Button, FormHelperText, Select,
  MenuItem, CircularProgress, Typography, Link } from '@mui/material'
import { Place } from '../../components/Place/Place'
import {createNewCustomer, getDeviceApps} from '../../services/client'
import { applyMask, textIsValid } from '../../services/utils'
import { validationMessages } from '../../services/helper'
import { SketchPicker } from 'react-color'
import './OnboardingApp.css'
const debug = false // requires testQuestions to be set in test-app-json
const debugLevel = 'default' // 'verbose'

interface Group {
  [key: string]: any;
}

type Props = {
  device?: boolean
}

function OnboardingApp({ device }: Props) {
  const history = useHistory()
  const location = useLocation()

  const [questions, setQuestions] = useState<any[]>([])

  /**
   * Component State
   */
  const [app, setApp] = useReducer(
      (state: any, newState: any) => ({ ...state, ...newState }), {}
  )
  const [target, setTarget] = useState<string>('')
  const [inputButtonDisabled, setInputButtonDisabled] = useState<boolean>(true)
  const [groupInputButtonDisabled, setGroupInputButtonDisabled] = useState<boolean>(true)
  const [targetHistory, setTargetHistory] = useState<string[]>([])
  const [validationMessage, setValidationMessage] = useState<string>('')
  const [applicationTitle, setApplicationTitle] = useState<string>(``)
  const [applicationHeading, setApplicationHeading] = useState<string>(``)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [groupValidations, setGroupValidations] = useState<Group>({})
  const [errorText, setErrorText] = useState<boolean>(false)
  const [loanOfficerEmail, setLoanOfficerEmail] = useState<string>('')
  const [uri, setUri] = useState<string>('')
  const wrapperFocus = useRef<any>(null)
  let questionText = ''

  const setAppQuestions = async () => {
    let appQuestions = []
    if (device) {
      const paths = location.pathname.split('/')
      const sn = paths.pop() || ''
      try {
        appQuestions = await getDeviceApps(sn)
      } catch (e) {
        alert('There was an error fetching the options')
      }
      if (sn) getDeviceApps(sn).then(apps => setQuestions(apps)).catch(e => alert('Error fetching questions'))
    }
    else appQuestions = require('../../onboarding.json')
    try {
      if (appQuestions.length) {
        // Set initial target and default settings
        const target: string = appQuestions[0].qId
        !!appQuestions && appQuestions.length && setTarget(target)
        if (appQuestions[0].title) setApplicationTitle(appQuestions[0].title)
        else setApplicationTitle(`Let's Get Started`)
        if (appQuestions[0].heading) setApplicationHeading(appQuestions[0].heading)
        else setApplicationHeading(`Answer a few questions to see if you Prequalify`)
        setQuestions(appQuestions)
        // @ts-ignore
        setTargetHistory([target])
      }
    } catch (e) {
      // if (typeof questionsOverride !== 'string') alert('Error fetching questions. Check console for error.')
      console.log(e)
    }
  }

  /**
   * On Load
   */
  useMemo(() => {
    setAppQuestions()
  }, [device, location])

  /**
   * Set focus on wrapper div to handle Enter press
   */
  useEffect(() => {
    wrapperFocus?.current?.focus();
  }, [])

  /**
   * Track changes to app
   */
  useEffect(() => {
    console.log(JSON.stringify(loanOfficerEmail))
  }, [loanOfficerEmail])

  /**
   * Handlers
   */
  const handleChoiceButtonPress = async (event: any, question: any, option: any) => {
    if (option.loanOfficerEmail) setLoanOfficerEmail(option.loanOfficerEmail)
    if (option.target) {
      if (option.uri) setUri(option.uri)
      setApp({ [question.fieldId]: option.value })
      await setNextTarget(option.target)
    }
    else if (option.uri) return window.location.href = `${option.uri}`

    setNextTarget(question.target)

  }

  const handlePickerValueChange = (event: any, question: any) => {
    // todo: enable/disable button
    let value = event.target.value
    setInputButtonDisabled(!value)
    setApp({ [question.fieldId]: value })
  }

  const handleGroupPickerValueChange = (event: any, question: any, group: any) => {
    // todo: enable/disable button
    let value = event.target.value
    if (question.type === 'row')  {
      const rowQuestion = question.items.find((item: any) => item.type === "picker")
      setApp({ [rowQuestion.fieldId]: value })
    } else {
      setApp({ [question.fieldId]: value })
    }

    handleGroupButtonToggle(value, question, group)
  }

  const handleUploadImage = (e: any, question: any) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setApp({ [question.fieldId]: reader?.result || '' })
    };
    setInputButtonDisabled(!file)

    reader.readAsDataURL(file);
  };

  const handleClearInputEvent = (event: any) => {
    event.target.value = "";
  };

  const handleChangeColor = (color: any, e: any, question: any) => {
    setInputButtonDisabled(!color.hex)
    setApp({ [question.fieldId]: color.hex })
  }

  const handleInputChangeText = (evt: any, question: any) => {

    // Validate input to enable button
    let newValue = applyMask(evt.target.value, question.validation)

    if (!!question.validation) setInputButtonDisabled(!textIsValid(newValue, question.validation))
    else setInputButtonDisabled(!((!question.validation || question.optional) && newValue.length > 1))

    setApp({ [question.fieldId]: newValue })

  }

  const handleGroupButtonToggle = (value: any, question: any, group: any) => {
    let valid = true
    for (let i in group.items) {
      const item = group.items[i]
      if (Boolean(item.optional)) continue
      let itemValue

      if (item.type === 'row') {
        for (let x in item.items) {
          const child = item.items[x]
          if (Boolean(child.optional)) continue
          itemValue = child.fieldId === question.fieldId ? value : ( app[child.fieldId] || '' )
          if (!textIsValid(itemValue, item.validation)) {
            valid = false
            break
          }
        }
      } else {
        itemValue = item.fieldId === question.fieldId ? value : (app[item.fieldId] || '')
        if (!textIsValid(itemValue, item.validation)) {
          valid = false
          break
        }
      }

      // @ts-ignore
      debug && debugLevel === 'verbose' && console.log(item.fieldId, itemValue, item.validation, textIsValid(itemValue, item.validation))

    }

    setGroupInputButtonDisabled(!valid)
  }

  const handleInputBlur = (event: any, question: any) => {
    const value: any = Object.entries(app).find((item) => item[0] === question.fieldId)
    let message
    if (Boolean(question.validation) && value?.length && !textIsValid(value[1], question.validation)) { // @ts-ignore
      message = validationMessages[question.validation]
    }
    else if (value === undefined || (!question.optional && value[1].length < 1)) message = validationMessages['required']
    setValidationMessage(message)
  }

  const handleGroupInputBlur = (event: any, question: any) => {
    const value: any = Object.entries(app).find((item) => item[0] === question.fieldId)
    let message: any = {}
    message[question.qId] = false
    if (!question.optional) {
      if (value === undefined || (!question.validation && value[1].length === 0)) message[question.qId] = validationMessages['required']
      else if (!textIsValid(value[1], question.validation)) { // @ts-ignore
        message[question.qId] = validationMessages[question.validation]
      }
    }
    setGroupValidations(groupValidations => ({ ...groupValidations, ...message }))
  }

  const handleGroupInputChangeText = (evt: any, question: any, group: any) => {

    let newValue = applyMask(evt.target.value, question.validation)

    setApp({ [question.fieldId]: newValue })

    handleGroupButtonToggle(newValue, question, group)

  }

  const handleInputButtonPress = async (event: any, question: any) => {
    if (question.type === 'place' && typeof app[question.fieldId] !== 'object') {

      const address = {
        address1: app[question.fieldId],
        address2: 'not captured',
        city: 'not captured',
        state: 'not captured',
        zipcode: 'not captured'
      }
      setApp({ [question.fieldId]: address })

    }

    setNextTarget(question.target)

  }

  const handleAddressSelection = (place: any, question: any, type: string='object') => {
    let fieldId = question.fieldId
    let index = ''

    if (!(place.address_components || []).length && type === 'object')
      return

    const address: any = {}

    const components: any = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
    }

    const result: any = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      postal_code: ''
    }

    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0]
      if (components[addressType]) {
        result[addressType] = place.address_components[i][components[addressType]]
      }
    }

    let matches = question.fieldId.match(/\d+$/)

    if (matches) {
      index = matches[0]
      //console.log(index, fieldId)
      fieldId = fieldId.substring(0, fieldId.length - index.length)
    }

    if (type === 'object') {
      address.address1 = result.street_number + ' ' + result.route
      address.city = result.locality
      address.state = result.administrative_area_level_1
      address.zipcode = result.postal_code
      setGroupInputButtonDisabled(false)

      setApp({ [question.fieldId]: address })
    } else if (type === 'individual') {
      setApp({
        [`${fieldId}Street`]: result.street_number + ' ' + result.route,
        [`${fieldId}City`]: result.locality,
        [`${fieldId}State`]: result.administrative_area_level_1,
        [`${fieldId}Zip`]: result.postal_code
      })
      setGroupInputButtonDisabled(false)
    }

  }

  /**
   * Navigation methods
   */
  const setNextTarget = (target: any): any => {
    const nextQ: any = questions.find(q => q.qId === target)
    if (!nextQ) return save()

    // Hide if
    if (nextQ.hideIf) {
      let statementParts
      // eslint-disable-next-line default-case
      switch (true) {
        case nextQ.hideIf.includes('!=='):
          statementParts=nextQ.hideIf.split('!==')
          if (app[statementParts[0]] !== statementParts[1]) return setNextTarget(nextQ.target)
          break
        case nextQ.hideIf.includes('==='):
          statementParts=nextQ.hideIf.split('===')
          if (app[statementParts[0]] === statementParts[1]) return setNextTarget(nextQ.target)
          break
        case nextQ.hideIf.includes('>='):
          statementParts=nextQ.hideIf.split('>=')
          if (app[statementParts[0]] >= statementParts[1]) return setNextTarget(nextQ.target)
          break
        case nextQ.hideIf.includes('>'):
          statementParts=nextQ.hideIf.split('>')
          if (app[statementParts[0]] > statementParts[1]) return setNextTarget(nextQ.target)
          break
        case nextQ.hideIf.includes('<='):
          statementParts=nextQ.hideIf.split('<=')
          if (app[statementParts[0]] <= statementParts[1]) return setNextTarget(nextQ.target)
          break
        case nextQ.hideIf.includes('<'):
          statementParts=nextQ.hideIf.split('<')
          if (app[statementParts[0]] < statementParts[1]) return setNextTarget(nextQ.target)
          break
      }

    }

    // Set title and heading
    if (!!nextQ.title) setApplicationTitle(replaceVars(nextQ.title))
    if (!!nextQ.heading) setApplicationHeading(replaceVars(nextQ.heading))

    // Set the target question to show
    setTarget(target)

    // Check if q is a group or a single question
    if (nextQ.type === 'group') {
      let invalid = false
      nextQ.items.forEach((item: any) => {
        if ((!app[item.fieldId] && !item.optional) || (app[item.fieldId] && !textIsValid(app[item.fieldId]))) invalid = true
      })
      setGroupInputButtonDisabled(invalid)
    }
    setInputButtonDisabled(!nextQ.optional && nextQ.type !== 'range' && !app[nextQ.fieldId])
    setGroupInputButtonDisabled(!nextQ.optional && nextQ.type !== 'range' && !app[nextQ.fieldId])
    // @ts-ignore
    setTargetHistory((targetHistory: any) => [...targetHistory, target])
  }

  const setPrevTarget = () => {
    const target: any = targetHistory[targetHistory.length-2]
    const prevQ: any = questions.find(q => q.qId === target)
    setTarget(prevQ.qId)

    if (!!prevQ.title) setApplicationTitle(replaceVars(prevQ.title))
    if (!!prevQ.heading) setApplicationHeading(replaceVars(prevQ.heading))

    setInputButtonDisabled(false)
    setGroupInputButtonDisabled(false)

    targetHistory.pop()
    setTargetHistory(targetHistory)
    wrapperFocus?.current?.focus();
  }

  const onEnterPress = async (e: any) => {
    const target: any = targetHistory[targetHistory.length-1]
    const nextQ: any = questions.find(q => q.qId === target)
    if (e.key === "Enter" && (!inputButtonDisabled || !groupInputButtonDisabled) && nextQ.type !== 'choice' && nextQ.type !== 'boolean') {
      await handleInputButtonPress(e, nextQ);
    }
  }

  /**
   * Save app to API
   */
  const save = async () => {
    const data = { ...app }

    console.log(uri)
    if (uri) {
      return window.location.href = `${uri}/${app[`OpenHouseAddressStreet`]}, ${app[`OpenHouseAddressCity`]}, ${app[`OpenHouseAddressState`]} ${app[`OpenHouseAddressZip`]}/${loanOfficerEmail}`
    }

    for (const [key, value] of Object.entries(data)) {
      if (key.includes('Amount') || key.includes('Value') || key.includes('Price') || key.includes('Balance'))
      { // @ts-ignore
        data[key] = parseFloat(value.toString().replace(/\D/g,''))
      }
    }

    // @ts-ignore
    if (debug || typeof questionsOverride === 'string') {
      console.log('App Data', JSON.stringify(( data )))
      alert('DEBUG MODE: App Complete')
      return
    }

    setSubmitted(true)
    setInputButtonDisabled(true)
    setGroupInputButtonDisabled(true)
    setValidationMessage('')
    setApplicationHeading('Please wait while your application is being submitted')
    createNewCustomer(data)
        .then((response) => {
          setSubmitted(false)
          setApplicationHeading('')
          setNextTarget('SuccessRegistration')
          history.push("/")
        })
        .catch((err) => {
          setErrorText(true)
          setSubmitted(false)
        })
  }

  /**
   * Replace {str} with app[str]
   * @param str
   * @returns {*}
   */
  const replaceVars = (str: string) => {
    let vars = []
    let re = /{{([^}]+)}}/g
    let text

    // eslint-disable-next-line no-cond-assign
    while(text = re.exec(str)) {
      vars.push(text[1])
    }
    vars.forEach(v => {
      str = str.replace('{{' + v + '}}', app[v])
    })

    return str
  }

  if (!questions.length) return (
      <div className="loading-container">
        <CircularProgress />
      </div>
  )

  /**
   * Foundation
   */
  return (
      <div className={ `auth-wrapper ${device ? 'device' : 'pos' }` } onKeyDown={e => onEnterPress(e)} tabIndex={0} ref={wrapperFocus}>
        <div className="onboarding-content-wrapper">
          <Typography variant="h5" className="onboarding-title">
            { applicationTitle }
          </Typography>
          {applicationHeading && <Typography variant="h5" className="onboarding-sub-title">
            { applicationHeading }
          </Typography>}
          { submitted && <CircularProgress /> }
          { !submitted &&
          <div>
            { questions.map((q: any) => {
              questionText = replaceVars(q.question)
              switch (q.type) {
                case 'choice':
                  // @ts-ignore
                  return (
                      <div key={ q.qId } style={{ display: q.qId !== target ? 'none' : 'block' }}>
                        <p>{ questionText }</p>
                        {
                          q.options.map((option: any) => {
                            return (
                                <Button
                                    className="auth-form-button"
                                    id={`${q.qId}${option.value}ChoiceButton`}
                                    variant={app[q.fieldId] === option.value ? 'contained' : 'outlined'}
                                    key={ `${ q.qId }_${ option.value }` }
                                    onClick={ e => handleChoiceButtonPress(e, q, option) }
                                >
                                  {option.label}
                                </Button>
                            )
                          })
                        }
                      </div>
                  )
                case 'boolean':
                  return (
                      <div key={ q.qId } style={{ display: q.qId !== target ? 'none' : 'block' }}>
                        <Typography variant="subtitle1" className="onboarding-question-text">{ questionText }</Typography>
                        {
                          q.options.map((option: any) => {
                            return (
                                <Button
                                    className="auth-form-button"
                                    id={`${q.qId}${option.value?'True':'False'}BooleanButton`}
                                    variant={app[q.fieldId] === option.value ? 'contained' : 'outlined'}
                                    key={ `${ q.qId }_${ option.value }` }
                                    onClick={ e => handleChoiceButtonPress(e, q, option) }
                                >
                                  <Typography variant="button">{option.label}</Typography>
                                </Button>
                            )
                          })
                        }
                      </div>
                  )
                case 'text':
                  return (
                      <div key={ q.qId } style={{ display: q.qId !== target ? 'none' : 'block' }}>
                        <Typography variant="subtitle1" className="onboarding-question-text">{ questionText }</Typography>
                        <FormControl className="onboarding-form-input">
                          <InputLabel>{q.name ? q.name : null}</InputLabel>
                          <Input
                              id={q.qId}
                              type={q.inputType || 'text'}
                              placeholder={ q.placeholder || questionText }
                              onBlur={ e => handleInputBlur(e, q) }
                              onChange={ e => handleInputChangeText(e, q) }
                              value={app[q.fieldId] || ""}
                          />

                          <FormHelperText className="error-text">
                            { validationMessage }
                          </FormHelperText>
                        </FormControl>

                        <Button
                            variant="contained"
                            color="primary"
                            className="auth-form-button"
                            id={`${q.qId}Button`}

                            onClick={ e => handleInputButtonPress(e, q) }
                            disabled={ inputButtonDisabled }
                        >
                          {q.target ? 'Continue' : 'Finish'}
                        </Button>
                      </div>
                  )
                case 'picker':
                  return (
                      <div key={ q.qId } style={{ display: q.qId !== target ? 'none' : 'block' }}>
                        <Typography variant="subtitle1" className="onboarding-question-text">{ questionText }</Typography>
                        <FormControl className="onboarding-form-input">
                          {!!q.label && <InputLabel>{ q.label }</InputLabel> }
                          <Select
                              style={{ borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }}
                              id={q.qId}
                              value={ app[q.fieldId] || '' }
                              onChange={ e => handlePickerValueChange(e, q) }
                          >
                            { !app[q.fieldId] && <MenuItem value={``} /> }
                            {q.options.map((option: any, index: number) => (
                                <MenuItem key={index} value={option.value}>
                                  {option.label}
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <p>{ validationMessage }</p>

                        <Button
                            variant="contained"
                            color="primary"
                            className="auth-form-button"
                            id={`${q.qId}Button`}

                            onClick={ e => handleInputButtonPress(e, q) }
                            disabled={ inputButtonDisabled }
                        >
                          {q.target ? 'Continue' : 'Finish'}
                        </Button>
                      </div>
                  )
                case 'place':
                  return (
                      <div key={ q.qId } style={{ display: q.qId !== target ? 'none' : 'block',
                        position: q.qId === target ? 'relative' : 'static', zIndex: 9999 }}>
                        <Typography variant="subtitle1" className="onboarding-question-text">{ questionText }</Typography>
                        <FormControl className="onboarding-form-input">
                          <Place onPress={ (p = {}) => handleAddressSelection(p, q) } />
                        </FormControl>

                        <Button
                            variant="contained"
                            color="primary"
                            className="auth-form-button"
                            id={`${q.qId}Button`}

                            onClick={ e => handleInputButtonPress(e, q) }
                            disabled={ inputButtonDisabled }
                        >
                          <Typography variant="button">
                            {q.target ? 'Continue' : 'Finish'}
                          </Typography>
                        </Button>
                      </div>
                  )
                case 'information':
                  return (
                      <div key={ q.qId } style={{ display: q.qId !== target ? 'none' : 'block' }}>
                        <Typography variant="body1" className="onboarding-information-text">{ questionText }</Typography>
                        {errorText &&
                        <>
                          <Typography className='onboarding-error-text'>
                            Your request could not be completed. Please email&nbsp;
                            <Link href={`mailto:support@thebigpos.com`}>
                              support@thebigpos.com
                            </Link>
                            &nbsp;to complete your enrollment.
                          </Typography>
                        </>
                        }
                        <Button
                            variant="contained"
                            color="primary"
                            className="auth-form-button"
                            id={`${q.qId}Button`}
                            onClick={ e => handleInputButtonPress(e, q) }
                        >
                          <Typography variant="button">{q.buttonText}</Typography>
                        </Button>
                      </div>
                  )
                case 'upload':
                  return (
                      <div key={ q.qId } style={{ display: q.qId !== target ? 'none' : 'block' }}>
                        <Typography variant="subtitle1" className="onboarding-question-text">{ questionText }</Typography>
                        <FormControl className="onboarding-form-input">
                          <input
                              accept="image/*"
                              style={{ display: 'none' }}
                              id="raised-button-file"
                              multiple
                              type="file"
                              onChange={e => handleUploadImage(e, q)}
                              onClick={e => handleClearInputEvent(e)}
                          />
                          <label htmlFor="raised-button-file">
                            <Button variant="contained" component="span" color="primary">
                              Choose File
                            </Button>
                          </label>
                          <div className="onboarding-upload-file">
                            <Typography className="onboarding-upload-title">
                              { !!app[q.fieldId] && <div>Selected file: <img alt={q.fieldId} src={app[q.fieldId]} width="200" /></div> }
                            </Typography>
                          </div>
                        </FormControl>

                        <Button
                            variant="contained"
                            color="primary"
                            className="auth-form-button"
                            id={`${q.qId}Button`}

                            onClick={ e => handleInputButtonPress(e, q) }
                            disabled={ inputButtonDisabled }
                        >
                          {q.target ? 'Continue' : 'Finish'}
                        </Button>
                      </div>
                  )
                case 'color':
                  return (
                      <div key={ q.qId } style={{ display: q.qId !== target ? 'none' : 'block' }}>
                        <Typography variant="subtitle1" className="onboarding-question-text">{ questionText }</Typography>
                        <FormControl className="onboarding-form-input">
                          <SketchPicker
                              color={app[q.fieldId] || ""}
                              className="onboarding-color-picker"
                              onChange={(color, e) => handleChangeColor(color, e, q)}
                          />
                          <div className="onboarding-upload-file">
                            <Typography className="onboarding-upload-title">
                              Selected color: {app[q.fieldId] || ""}
                            </Typography>
                          </div>
                        </FormControl>

                        <Button
                            variant="contained"
                            color="primary"
                            className="auth-form-button"
                            id={`${q.qId}Button`}

                            onClick={ e => handleInputButtonPress(e, q) }
                            disabled={ inputButtonDisabled }
                        >
                          {q.target ? 'Continue' : 'Finish'}
                        </Button>
                      </div>
                  )
                case 'group':
                  if (!!q.items && q.items.length) {
                    return (
                        <div key={ q.qId } style={{ display: q.qId !== target ? 'none' : 'block' }}>
                          <p>{ questionText }</p>
                          {
                            q.items.map((item: any, index: number) => {
                              questionText = replaceVars(item.question)

                              switch (item.type) {
                                case 'row':
                                  return <div key={`${item.qId}row`} style={{ flexDirection: "row" }}>
                                    {
                                      item.items.map((child: any) => {
                                        questionText = replaceVars(child.question)
                                        switch (child.type) {
                                          case 'text':
                                            return (
                                                <div key={ child.fieldId } style={ child.style }>
                                                  <FormControl className="onboarding-form-input">
                                                    <InputLabel>{questionText}</InputLabel>
                                                    <Input
                                                        id={child.qId}
                                                        placeholder={ questionText }
                                                        onBlur={ e => handleGroupInputBlur(e, child) }
                                                        onChange={ e => handleGroupInputChangeText(e, child, q) }
                                                        value={app[child.qId] || ""}
                                                    />
                                                    { groupValidations[child.qId] &&
                                                    <FormHelperText className="error-text">
                                                      { validationMessage }
                                                    </FormHelperText> }
                                                  </FormControl>
                                                </div>
                                            )
                                          case 'picker':
                                            return (
                                                <div key={ child.fieldId } style={ child.style }>
                                                  <FormControl className="onboarding-form-input">
                                                    <InputLabel>{questionText}</InputLabel>
                                                    <Select
                                                        style={{ borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                                                        id={child.qId}
                                                        value={ app[child.fieldId] || '' }
                                                        onChange={ e => handleGroupPickerValueChange(e, item, q) }
                                                    >
                                                      { !app[child.fieldId] && <MenuItem value={``} /> }
                                                      {child.options.map((option: any, index: number) => (
                                                          <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                          </MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                </div>
                                            )
                                          default:
                                            return <></>
                                        }
                                      })
                                    }
                                  </div>
                                case 'text':
                                  return (
                                      <div key={ item.qId }>
                                        <FormControl className="onboarding-form-input">
                                          <InputLabel>{ questionText }</InputLabel>
                                          <Input
                                              id={item.qId}
                                              placeholder={ questionText }
                                              onBlur={ e => handleGroupInputBlur(e, item) }
                                              onChange={ e => handleGroupInputChangeText(e, item, q) }
                                              value={app[item.qId] || ""}
                                          />
                                          {groupValidations[item.qId] && (
                                              <FormHelperText className="error-text">
                                                { groupValidations[item.qId] }
                                              </FormHelperText>
                                          )}
                                        </FormControl>
                                      </div>
                                  )
                                case 'picker':
                                  return (
                                      <div key={ item.qId } style={ item.style }>
                                        <FormControl className="onboarding-form-input">
                                          <InputLabel>{ questionText }</InputLabel>
                                          <Select
                                              style={{ borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                                              id={ item.qId }
                                              value={ app[item.fieldId] || '' }
                                              onChange={ e => handleGroupPickerValueChange(e, item, q) }
                                          >
                                            { !app[item.fieldId] && <MenuItem value={``} /> }
                                            {item.options.map((option: any, index: number) => (
                                                <MenuItem key={index} value={option.value}>
                                                  {option.label}
                                                </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                  )
                                case 'place':
                                  return (
                                      <div key={ item.qId } >
                                        <FormControl className="onboarding-form-input">
                                          <Place
                                              placeholder={item.placeholder}
                                              onPress={ (p = {}) => handleAddressSelection(p, item, 'individual') }
                                          />
                                        </FormControl>
                                      </div>
                                  )
                                default:
                                  return <></>
                              }
                            })
                          }
                          <Button
                              variant="contained"
                              color="primary"
                              className="auth-form-button"
                              id={`${q.qId}Button`}

                              onClick={ e => handleInputButtonPress(e, q) }
                              disabled={ groupInputButtonDisabled }
                          >
                            <Typography variant="button">
                              {q.target ? 'Continue' : 'Finish'}
                            </Typography>
                          </Button>
                        </div>
                    )
                  }
                  break
                default:
                  return <></>
              }
              return <></>
            }) }
            { targetHistory.length > 1 && !targetHistory.includes('SuccessRegistration') &&
            <Button
                className="auth-form-button"
                id={`BackButton`}
                onClick={ setPrevTarget }
            >
              <Typography variant="button">Back</Typography>
            </Button>
            }
          </div>
          }
        </div>
      </div>
  )
}

export default OnboardingApp