import React, { useReducer } from 'react'
import './ForgotPassword.css'
import { FormControl, InputLabel, Input, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { isEmailValid } from '../../services/helper'
import { useWindowDimensions } from '../../services/utils'
import logo from '../../assets/images/logo.png'
import theme from '../../config'

function ForgotPassword() {
	const { width } = useWindowDimensions();
	const [formInput, setFormInput] = useReducer(
		(state: any, newState: any) => ({ ...state, ...newState }),
		{
			email: '',
		}
	);

	const enabledSignInButton = isEmailValid(formInput.email);

	const handleSubmit = (evt: any) => {
		evt.preventDefault();

		let data = { formInput };

		console.log('DATA: ', data);
	};

	const handleInput = (evt: any) => {
		const name = evt.target.id;
		const newValue = evt.target.value;
		setFormInput({ [name]: newValue });
	};

	return (
		<div className="auth-wrapper">
			{width >= theme.authWrapperLogoWidth && (
				<img className="auth-logo-top" src={logo} alt="logo" />
			)}
			<div className="auth-content-wrapper">
				{width <= theme.authWrapperLogoWidth && (
					<img className="auth-logo" src={logo} alt="logo" />
				)}
				<h1 className="auth-title">Password Reset</h1>
				<form onSubmit={handleSubmit} className="auth-form-wrapper">
					<FormControl className="auth-form-input">
						<InputLabel htmlFor="email">Enter your email address</InputLabel>
						<Input
							id="email"
							defaultValue={formInput.email}
							onChange={handleInput}
						/>
					</FormControl>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						className="auth-form-button"
						disabled={!enabledSignInButton}
					>
						Submit
					</Button>
				</form>
				<div className="auth-link-wrapper">
					<p>I think I remember. &nbsp;</p>
					<Link to="/sign-in" className="auth-link">
						Sign In
					</Link>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;