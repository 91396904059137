import { useState, useEffect } from 'react'
import { isDateValid, isDOBValid, isEmailValid, isPhoneNumberValid, isPositiveInt,
	isSSNValid, isValidCurrency, isValidDecimal, isValidMonths, isValidYear,
	isValidYears } from './helper'
import theme from '../config'

export const applyMask = (value: string, maskType: string) => {
	let x: any
	switch (maskType) {
		case 'ssn':
			x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
			return !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
		case 'date':
			x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
			return !x[2] ? x[1] : x[1] + '/' + x[2] + (x[3] ? '/' + x[3] : '')
		case 'year':
			return value.replace(/\D/g, '').substring(0,4);
		case 'dob':
			x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
			return !x[2] ? x[1] : x[1] + '/' + x[2] + (x[3] ? '/' + x[3] : '')
		case 'phone':
			x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
			return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
		case 'currency':
			x = parseInt(value.toString().replace(/\D/g,""),10)
			if (isNaN(x)) return ''
			return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		case 'fullCurrency':
			return value
		case 'alpha':
			return value.replace(/[^a-z ,-]+/gi,'');
		case 'numeric':
			return value.replace(/\D/g,'');
		case 'alphanumeric':
			return value.replace(/[^\w\s,-]/gi, '');
		case 'years':
			return value.replace(/\D/g,'');
		case 'months':
			return value.replace(/\D/g,'');
		case 'positive':
			return value.replace(/\D/g,'');
		default: return value
	}
}

export const textIsValid = function(value: any, type: any=null) {
	let valid
	switch (type) {
		case 'phone':
			valid = isPhoneNumberValid(value)
			break
		case 'email':
			valid = isEmailValid(value)
			break
		case 'ssn':
			valid = isSSNValid(value)
			break
		case 'dob':
			valid = isDOBValid(value)
			break
		case 'date':
			valid = isDateValid(value)
			break
		case 'year':
			valid = isValidYear(value)
			break
		case 'years':
			valid = isValidYears(value)
			break
		case 'months':
			valid = isValidMonths(value)
			break
		case 'currency':
			valid = isValidCurrency(value)
			break
		case 'decimal':
			valid = isValidDecimal(value)
			break
		case 'positive':
			valid = isPositiveInt(value)
			break
		case 'alpha':
			valid = value.length > 0
			break
		case 'numeric':
			valid = !isNaN(parseInt(value, 10)) && value.length > 0
			break
		case 'alphanumeric':
			valid = value.length > 0
			break
		case 'password':
			valid = value.length >= theme.validation.passwordLength
			break
		case null:
			valid = value.length > 0
			break
		default:
			// @ts-ignore
			const exp = new RegExp(RegExp.escape(type))
			valid = exp.test(value)
	}

	return valid
}

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	}
}

export const useWindowDimensions = () => {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	)

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowDimensions
}