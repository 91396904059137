import React, {useEffect, useState} from 'react'
import { Typography, CircularProgress } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { DataTable } from '../../components/DataTable/DataTable'
import './Clients.css'
import { Header } from '../../components/Header/Header'
import { getNewCustomers } from '../../services/client'

const headers = [
    {id: 'CompanyName', name: 'Name'},
    {id: 'ContactEmail', name: 'Email'},
    {id: 'ContactPhone', name: 'Phone'},
    {id: 'Domain', name: 'Domain'},
    {id: 'Status', name: 'Status'},
    {id: 'CreatedAt', name: 'Created'},
]
const fields = ['CompanyName', 'ContactEmail', 'ContactPhone', 'Domain', 'Status', 'CreatedAt']

function Clients() {
    const history = useHistory()

    const [tableData, setTableData] = useState<object[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        getNewCustomers().then(data => {
            data.sort((a: any, b: any) => {
                let startDate: any = new Date(a.CreatedAt)
                let endDate: any = new Date(b.CreatedAt)
                return endDate - startDate
            })
            setTableData(data)
        }).finally(() => setIsLoading(false))
    }, [])

    const onEditClient = (row: any) => {
        history.push(`/client/${row.ID}`, { row })
    }

    return (
        <>
            <Header />
            <div className='table--wrapper'>
                <Typography variant='h4' className='table--title'>
                    Clients
                </Typography>
                { !isLoading &&
				<DataTable data={tableData} headers={headers} fields={fields} onEditClient={onEditClient} />
                }
            </div>
            { isLoading &&
			<div className='table--spinner'>
				<CircularProgress size={50} />
			</div>
            }
        </>
    )
}

export default Clients