import React, { useState, useEffect, useRef } from 'react'
import { AppBar, Toolbar, IconButton, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useVenti } from 'venti'
import { SideBar } from '../SideBar/SideBar'
import theme from '../../config'
import { userLogoutAction } from '../../store/user'
import { useHistory } from 'react-router-dom'
import './Header.css'

export const Header = () => {
    const ventiState = useVenti()
    const history = useHistory()
    const user = ventiState.get(theme.storageKeys.user, {})
    const [openUserMenu, setOpenUserMenu] = useState(false)
    const [sideBarOpen, setSideBarOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const handleOpenSideBar = () => {
        setSideBarOpen(!sideBarOpen)
    }

    const handleToggleUserMenu = () => {
        setOpenUserMenu((prevOpen) => !prevOpen)
    }

    const handleCloseUserMenu = (event: any) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }

        setOpenUserMenu(false)
    }

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpenUserMenu(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(openUserMenu)
    useEffect(() => {
        if (prevOpen.current && !openUserMenu) {
            anchorRef.current!.focus()
        }

        prevOpen.current = openUserMenu
    }, [openUserMenu])

    const logOut = () => {
        userLogoutAction()
        history.push('/sign-in')
    }

    return <>
        <AppBar position='static' color='transparent'>
            <Toolbar className='header-toolbar'>
                <IconButton
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    onClick={handleOpenSideBar}
                    size="large">
                    <MenuIcon />
                </IconButton>
                {sideBarOpen && (
                    <SideBar
                        open={sideBarOpen}
                        onOpen={handleOpenSideBar}
                        onClose={handleOpenSideBar}
                    />
                )}
                <>
                    <Button
                        ref={anchorRef}
                        aria-controls={openUserMenu ? 'menu-list-grow' : undefined}
                        aria-haspopup='true'
                        onClick={handleToggleUserMenu}
                        className='header-user-button'
                        variant='contained'
                        color='primary'
                    >
                        {user?.FirstName.substring(0,1)}
                    </Button>
                    <Popper
                        open={openUserMenu}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={event => handleCloseUserMenu(event)} >
                                        <MenuList
                                            autoFocusItem={openUserMenu}
                                            id='menu-list-grow'
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <MenuItem onClick={logOut}>Logout</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            </Toolbar>
        </AppBar>
    </>;
}
