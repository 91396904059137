import React, {useEffect, useState} from 'react'
import {Typography, CircularProgress, Link} from '@mui/material'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import { Header } from '../../../components/Header/Header'
import { useHistory } from 'react-router-dom'
import './Client.css'
import {ArrowBack} from "@mui/icons-material";

const fields = [
    { name: 'CompanyName', value: '', type: 'field', label: 'Company Name' },
    { name: 'ContactEmail', value: '', type: 'field', label: 'Contact Email' },
    { name: 'ContactFirstName', value: '', type: 'field', label: 'Contact First Name' },
    { name: 'ContactLastName', value: '', type: 'field', label: 'Contact Last Name' },
    { name: 'ContactPhone', value: '', type: 'field', label: 'Contact Phone' },
    { name: 'ContactPosition', value: '', type: 'field', label: 'Contact Position' },

    { name: 'CompanyAddressCity', value: '', type: 'field', label: 'Company Address City' },
    { name: 'CompanyAddressState', value: '', type: 'field', label: 'Company Address State' },
    { name: 'CompanyAddressStreet', value: '', type: 'field', label: 'Company Address Street' },
    { name: 'CompanyAddressUnit', value: '', type: 'field', label: 'Company Address Unit' },
    { name: 'CompanyAddressZip', value: '', type: 'field', label: 'Company Address Zip' },
    { name: 'CompanyLicensedAs', value: '', type: 'field', label: 'Company Licensed As' },
    { name: 'LogoURL', value: '', type: 'image', label: 'Logo URL' },

    { name: 'Domain', value: '', type: 'field', label: 'Domain' },
    { name: 'IsImportBranches', checked: false, type: 'checkbox', label: 'Import Branches' },
    { name: 'IsLOImport', checked: false, type: 'checkbox', label: 'LO Import' },
    { name: 'IsSocialSurvey', checked: false, type: 'checkbox', label: 'Social Survey' },
    { name: 'PrimaryColor', value: '', type: 'color', label: 'Primary Color' },
    { name: 'SecondaryColor', value: '', type: 'color', label: 'Secondary Color' },
]

function Client() {
    const history = useHistory<any>()
    const [editData1, setEditData1] = useState<{ name: string; value: string; checked: boolean; type: string; label: string }[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        setIsLoading(true)
        let propData = history?.location?.state?.row
        let data = Object.entries(propData).map(( [k, v] ) => ({ name: k, value: v }));
        let isBoolean = (val: any) => 'boolean' === typeof val;
        let fieldData = fields.reduce((acc: any, val: any) => {
            let fieldByName = data.find(item => item.name === val.name)
            if (!!fieldByName?.name && !isBoolean(fieldByName?.value)) {
                val.value = fieldByName?.value
            } else {
                val.checked = fieldByName?.value
            }
            return acc
        }, fields)
        setEditData1(fieldData)
        setIsLoading(false)
    }, [])

    return (
        <>
            <Header />
            <div className='edit-client--wrapper'>
                <Typography variant='h4' className='edit-client--title'>
                    <Link href={`/clients`} underline={`none`}><ArrowBack /></Link> Edit client
                </Typography>
                <div className='edit-client--fields-wrapper'>
                    {
                        editData1.map((field) => {
                                let value
                                switch (field.type) {
                                    case 'field': value = <TextField disabled className='edit-client--text-field' label={field.label} value={field.value} />
                                        break;
                                    case 'checkbox': value =
                                        <div className='edit-client--field-wrapper w100p'>
                                            <Typography variant='subtitle1'>{field.label}:</Typography>
                                            <Checkbox disabled className='edit-client--checkbox' checked={field?.checked} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                        </div>
                                        break;
                                    case 'color': value =
                                        <div className='edit-client--field-wrapper w100p'>
                                            <Typography variant='subtitle1'>{field.label}:</Typography>
                                            <div className='edit-client--color' style={{ backgroundColor: field.value }} /> {field.value}
                                        </div>
                                        break;
                                    case 'image':
                                        value =
                                            <div className='edit-client--field-wrapper'>
                                                <Typography variant='subtitle1'>{field.label}:</Typography>
                                                <img src={field.value} alt={field.name} className='edit-client--image' />
                                            </div>
                                        break;
                                }
                                return <div>{value}</div>
                            }
                        )
                    }
                </div>
            </div>
            { isLoading &&
			<div className='edit-client--spinner'>
				<CircularProgress size={50} />
			</div>
            }
        </>
    )
}

export default Client