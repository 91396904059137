import React, { useEffect, useState } from 'react'
import '../assets/styles/normalize.css'
import '../index.css'
import { state } from 'venti'
import { initialUserState } from '../store/user'
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { getItemAsyncStorage } from '../services/helper'
import PrivateRoute from '../components/PrivateRoute'
import themeConfig from '../config'
import '@mui/styles'
// @ts-ignore
import HttpsRedirect from 'react-https-redirect'

// Pages
import SignIn from './SignIn/SignIn'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import OnboardingApp from './OnboardingApp/OnboardingApp'
import Clients from './Clients/Clients'
import Client from './Clients/Client/Client'


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const { storageKeys } = themeConfig

const theme = createTheme(adaptV4Theme({
    palette: {
        primary: {
            main: '#DD3333',
        }
    },
    typography: {
        fontFamily:[
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    }
}))

export default function App() {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getItemAsyncStorage(storageKeys.authToken).then(token => {
            getItemAsyncStorage(storageKeys.user).then(user => {
                if (token) {
                    const userData = user ? JSON.parse(user) : initialUserState
                    state.set(storageKeys.authToken, token)
                    state.set(storageKeys.user,
                        user ? Object.assign(initialUserState, userData, { isLoggedIn: true }) : userData)
                }
            }).finally(() => setIsLoading(false))
        })
    }, [])

    if (isLoading) {
        return null
    }

    return (
        <HttpsRedirect>
            <React.StrictMode>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <Switch>
                                <Route path="/sign-in">
                                    <SignIn />
                                </Route>
                                <Route path="/forgot-password">
                                    <ForgotPassword />
                                </Route>
                                <PrivateRoute path="/clients">
                                    <Clients />
                                </PrivateRoute>
                                <PrivateRoute path="/client/:id" >
                                    <Client />
                                </PrivateRoute>
                                <Route path="/device-setup/:serialNumber">
                                    <OnboardingApp device />
                                </Route>
                                <Route path="/">
                                    <OnboardingApp />
                                </Route>
                            </Switch>
                        </Router>
                    </ThemeProvider>
                </StyledEngineProvider>
            </React.StrictMode>
        </HttpsRedirect>
    );
}