import React from 'react'
import { useHistory } from 'react-router-dom'
import { List, ListItem, IconButton, ListItemIcon, ListItemText, Divider, SwipeableDrawer } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import Close from '@mui/icons-material/Close'
import logo from '../../assets/images/logo.png'
import './SideBar.css'

type Props = {
    open: boolean;
    onOpen: any;
    onClose: any;
}

const listItems = ['Dashboard', 'Clients']

export const SideBar = (prop: Props) => {
    const history = useHistory()

    const handleNavigationClick = (item: string) => {
        history.push(`/${item.toLowerCase()}`)
    }

    const list = () => (
        <div
            role='presentation'
            onClick={prop.onClose}
            onKeyDown={prop.onClose}
            className='sidebar-wrapper'
        >
            <div className='sidebar-header'>
                <img src={logo} alt='logo' className='sidebar-header-logo' />
                <IconButton
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    onClick={prop.onClose}
                    size="large">
                    <Close />
                </IconButton>
            </div>
            <Divider />
            <List>
                {listItems.map((item, index) => (
                    <ListItem key={index} button onClick={() => handleNavigationClick(item)} >
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                    </ListItem>
                ))}
            </List>
        </div>
    )

    return (
        <div>
            <SwipeableDrawer
                open={prop.open}
                onOpen={prop.onOpen}
                onClose={prop.onClose}
            >
                {list()}
            </SwipeableDrawer>
        </div>
    )
}
