import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { state } from 'venti'
import themeConfig from '../config'

type Props = {
    children: JSX.Element,
    path: string,
}

export default function PrivateRoute(props: Props) {
    const { children, ...rest } = props
    const user = state.get(themeConfig.storageKeys.user)

    return (
        <Route
            {...rest}
            render={({ location }) =>
                user?.isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/sign-in",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    )
}
