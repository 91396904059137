import moment from 'moment'

/**
 * @param {string} email
 * @returns {boolean} - return TRUE if valid
 */
export const isEmailValid = function(email: string) {
	return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(email.toLowerCase());
}

/**
 * @param {string} phone
 * @returns {boolean} - return TRUE if valid
 */
export const isPhoneNumberValid = function(phone: string) {
	return /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone);
}

/**
 * @param {string} ssn
 * @returns {boolean} - return TRUE if valid
 */
export const isSSNValid = function(ssn: string) {
	return /^\d{3}-?\d{2}-?\d{4}$/.test(ssn);
}

export const hasEmptyProperties = (obj: any) => {
	if (typeof obj !== 'object') return true
	const values = Object.values(obj)
	console.log(values)
	for (let i in values) {
		if (!values[i]) return true
	}
	return false;
}

export const formatDate = (date: Date, includeTime = true) => {
	if (!date) return date
	const jsDate = new Date(date)
	let ret = (jsDate.getMonth() + 1) + '/' + jsDate.getDate() + '/' +  jsDate.getFullYear()
	if (includeTime) ret += ` ${formatAMPM(jsDate)}`
	return ret
}

export const formatAMPM = (date: Date) => {
	if (!date) return date
	let hours = date.getHours()
	let minutes = date.getMinutes().toString()
	let ampm = hours >= 12 ? 'pm' : 'am'
	hours = hours % 12
	hours = hours ? hours : 12
	minutes = minutes.padStart(2, '0')
	return hours + ':' + minutes + ' ' + ampm
}

/**
 * @param {string} dob
 * @returns {boolean} - return TRUE if valid
 */
export const isDOBValid = function(dob: string) {
	if (dob.length < 10) return false
	const m = moment(dob, "MM/DD/YYYY")
	const now = moment()
	const diff = now.diff(m, "seconds")
	return (dob.length === 10 && !isNaN(diff) && diff >= 567648000 )
}

export const isDateValid = function(dob: string) {
	if (dob.length < 10) return false
	const m = moment(dob, "MM/DD/YYYY")
	const now = moment()
	const diff = now.diff(m, "seconds")
	return (dob.length === 10 && !isNaN(diff) && diff >= 0 )
}

export const isPositiveInt = function(value: any) {
	return (value && !isNaN(value))
}

export const isValidYear = function(value: number) {
	return (value && !isNaN(value) && value >= 1500 && value <= new Date().getFullYear())
}

export const isValidYears = function(value: number) {
	return (value > -1 && !isNaN(value))
}

export const isValidMonths = function(value: number) {
	return (!isNaN(value) && value > -1 && value < 12)
}

export const isValidCurrency = function(value: any) {
	return (parseFloat(value) >= 0 && !isNaN(parseFloat(value)))
}
export const isValidDecimal = function(value: number) {
	return (value > -1 && !isNaN(value))
}

export const validationMessages = {
	'phone': 'Please enter a valid phone number',
	'email': 'Please enter a valid email address',
	'ssn': 'Please enter a valid Social Security Number',
	'date': 'Please enter a valid date in mm/dd/yyyy format',
	'dob': `Please enter a valid birthdate before ${moment().subtract(18, "years").toDate().toLocaleDateString()}`,
	'year': 'Please enter a valid year',
	'years': 'Please enter a valid number of years',
	'months': 'Please enter a valid number of months',
	'positive': 'Please enter a value greater than 0',
	'required': 'Please enter a value',
	'alpha': 'Please enter a value',
	'numeric': 'Please enter a value',
	'alphanumeric': 'Please enter a value',
	'password': 'Password does not meet the criterion'
}

export const googleAutocomplete = async (text: string) =>
	new Promise((resolve, reject) => {
		try {
			// @ts-ignore
			new window.google.maps.places.AutocompleteService().getPlacePredictions(
				{ input: text, types: ['geocode'], componentRestrictions: { country: "us" } },
				resolve
			)
		} catch (e) {
			reject(e)
		}
	})

export const geocode = async (placeId: any) =>
	new Promise((resolve, reject) => {
		try {
			// @ts-ignore
			new window.google.maps.Geocoder().geocode({ placeId }, resolve)
		} catch (e) {
			reject(e)
		}
	})

/**
 * @param {string} key
 * @param {string} data
 */
export const setItemAsyncStorage = async (key: string, data: string) => {
	try {
		await localStorage.setItem(key, data)
	} catch (error) {
		console.log('ERROR: setDataAsyncStorage ', key, data, error)
	}
}

/**
 * @param {string} key
 */
export const getItemAsyncStorage = async (key: string) => {
	try {
		return await localStorage.getItem(key)
	} catch (error) {
		console.log('ERROR: getItemAsyncStorage ', key, error)
		return null
	}
}
