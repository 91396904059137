import React, { useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Input, Paper } from '@mui/material'
import { Clear, Search } from '@mui/icons-material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

const styles = (theme:any) => ({
    root: {
        height: theme.spacing(6),
        display: "flex",
        justifyContent: "space-between",
    },
    iconButton: {
        color: theme.palette.action.active,
        transform: "scale(1, 1)",
        transition: theme.transitions.create(["transform", "color"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    iconButtonHidden: {
        transform: "scale(0, 0)",
        "& > $icon": {
            opacity: 0,
        },
    },
    searchIconButton: {
        marginRight: theme.spacing(-6),
    },
    icon: {
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    input: {
        width: "100%",
    },
    searchContainer: {
        margin: "auto 16px",
        width: `calc(100% - ${theme.spacing(6 + 4)}px)`, // 6 button + 4 margin
    },
})

type inputProps = any
   


/**
 * Material design search bar
 * @see [Search patterns](https://material.io/archive/guidelines/patterns/search.html)
 */
const SearchBar = forwardRef(
    (
        {
            cancelOnEscape,
            className,
            classes,
            closeIcon,
            disabled,
            onCancelSearch,
            onRequestSearch,
            searchIcon,
            style,
            value,
            onFocus,
            onBlur,
            onChange,
            onKeyUp
        }: inputProps,
        ref
    ) => {
        const inputRef = useRef()
        const [searchValue, setSearchValue] = useState(value)

        useEffect(() => {
            setSearchValue(value)
        }, [value])

        const handleFocus = useCallback(
            (e) => {
                if (onFocus) {
                    onFocus(e)
                }
            },
            [onFocus]
        )

        const handleBlur = useCallback(
            (e) => {
                setSearchValue((v:string) => v.trim())
                if (onBlur) {
                    onBlur(e)
                }
            },
            [onBlur]
        )

        const handleInput = useCallback(
            (e) => {
                setSearchValue(e.target.value)
                if (onChange) {
                    onChange(e.target.value)
                }
            },
            [onChange]
        )

        const handleCancel = useCallback(() => {
            setSearchValue('')
            if (onCancelSearch) {
                onCancelSearch()
            }
        }, [onCancelSearch])

        const handleRequestSearch = useCallback(() => {
            if (onRequestSearch) {
                onRequestSearch(value)
            }
        }, [onRequestSearch, value])

        const handleKeyUp = useCallback(
            (e) => {
                if (e.charCode === 13 || e.key === "Enter") {
                    handleRequestSearch()
                } else if (
                    cancelOnEscape &&
                    (e.charCode === 27 || e.key === "Escape")
                ) {
                    handleCancel()
                }
                if (onKeyUp) {
                    onKeyUp(e)
                }
            },
            [handleRequestSearch, cancelOnEscape, handleCancel, onKeyUp]
        )

        return (
            <Paper className={classNames(classes.root, className)} style={style}>
                <div className={classes.searchContainer}>
                    <Input
                        placeholder={`Search...`}
                        inputRef={inputRef}
                        onBlur={handleBlur}
                        value={searchValue}
                        onChange={handleInput}
                        onKeyUp={handleKeyUp}
                        onFocus={handleFocus}
                        fullWidth
                        className={classes.input}
                        disableUnderline
                        disabled={disabled}
                    />
                </div>
                <IconButton
                    onClick={handleRequestSearch}
                    className={classNames(classes.iconButton, classes.searchIconButton, {
                        [classes.iconButtonHidden]: value !== "",
                    })}
                    disabled={disabled}
                >
                    {cloneElement(searchIcon, {
                        classes: { root: classes.icon },
                    })}
                </IconButton>
                <IconButton
                    onClick={handleCancel}
                    className={classNames(classes.iconButton, {
                        [classes.iconButtonHidden]: value === "",
                    })}
                    disabled={disabled}
                >
                    {cloneElement(closeIcon, {
                        classes: { root: classes.icon },
                    })}
                </IconButton>
            </Paper>
        )
    }
)

SearchBar.defaultProps = {
    className: "",
    closeIcon: <Clear />,
    disabled: false,
    placeholder: "Search",
    searchIcon: <Search />,
    style: null,
    value: "",
}

SearchBar.propTypes = {
    /** Whether to clear search on escape */
    cancelOnEscape: PropTypes.bool,
    /** Override or extend the styles applied to the component. */
    classes: PropTypes.object.isRequired,
    /** Custom top-level class */
    className: PropTypes.string,
    /** Override the close icon. */
    closeIcon: PropTypes.node,
    /** Disables text field. */
    disabled: PropTypes.bool,
    /** Fired when the search is cancelled. */
    onCancelSearch: PropTypes.func,
    /** Fired when the text value changes. */
    onChange: PropTypes.func,
    /** Fired when the search icon is clicked. */
    onRequestSearch: PropTypes.func,
    /** Sets placeholder text for the embedded text field. */
    placeholder: PropTypes.string,
    /** Override the search icon. */
    searchIcon: PropTypes.node,
    /** Override the inline-styles of the root element. */
    style: PropTypes.object,
    /** The value of the text field. */
    value: PropTypes.string,
}

export default withStyles(styles)(SearchBar)