import React, { useState, useReducer } from 'react'
import './SignIn.css'
import { FormControl, InputLabel, Input, Button, FormHelperText,
	CircularProgress, Typography } from '@mui/material'
import { Link, useHistory } from 'react-router-dom'
import { isEmailValid } from '../../services/helper'
import { useWindowDimensions } from '../../services/utils'
import logo from '../../assets/images/logo.png'
import { authenticate, getMe } from '../../services/client';
import { userLoginAction } from '../../store/user';
import theme from '../../config'

function SignIn() {
	const history = useHistory()
	const { width } = useWindowDimensions();
	const [formInput, setFormInput] = useReducer(
		(state: any, newState: any) => ({ ...state, ...newState }),
		{
			email: '',
			password: '',
		}
	);
	const [emailValidation, setEmailValidation] = useState<boolean>(true);
	const [passwordValidation, setPasswordValidation] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');

	const enabledSignInButton =
		isEmailValid(formInput.email) &&
		formInput.password.length >= theme.validation.passwordLength;

	const emailOnBlur = () => setEmailValidation(isEmailValid(formInput.email));
	const passwordOnBlur = () =>
		setPasswordValidation(
			formInput.password.length >= theme.validation.passwordLength
		);

	const handleSubmit = (evt: any) => {
		evt.preventDefault()
		setLoading(true)
		authenticate(formInput.email, formInput.password)
			.then(async login => {
				if (login?.access_token) {
					setFormInput({
						email: '',
						password: ''
					})
					let tokenExp = login['.expires']
					const me = await getMe(login.access_token)
					if (me.Role !== 2) {
						setErrorMsg(`Not authorized.`)
						setLoading(false)
					} else {
						const userData = { ...login, ...me, tokenExp }
						await userLoginAction(userData)
						history.push('/clients')
					}
				} else {
					setErrorMsg('Please try again')
					setLoading(false)
				}

			})
			.catch((err) => {
				setErrorMsg(err && (err.data?.error_description || 'Please try again'))
				setLoading(false)
			})
	};

	const handleInput = (evt: any) => {
		const name = evt.target.id;
		const newValue = evt.target.value;
		setFormInput({ [name]: newValue });
	};

	return (
		<div className="auth-wrapper">
			{width >= theme.authWrapperLogoWidth && (
				<img className="auth-logo-top" src={logo} alt="logo" />
			)}
			<div className="auth-content-wrapper">
				{width < theme.authWrapperLogoWidth && (
					<img className="auth-logo" src={logo} alt="logo" />
				)}
				<h1 className="auth-title">Sign in to your account</h1>
				<form onSubmit={handleSubmit} className="auth-form-wrapper">
					<FormControl className="auth-form-input">
						<InputLabel htmlFor="email">Email</InputLabel>
						<Input
							id="email"
							onBlur={emailOnBlur}
							defaultValue={formInput.email}
							aria-describedby="my-helper-text"
							onChange={handleInput}
						/>
						{!emailValidation && (
							<FormHelperText className="error-text">
								Email is not valid
							</FormHelperText>
						)}
					</FormControl>
					<FormControl className="auth-form-input">
						<InputLabel htmlFor="password">Password</InputLabel>
						<Input
							id="password"
							onBlur={passwordOnBlur}
							type="password"
							defaultValue={formInput.password}
							onChange={handleInput}
						/>
						{!passwordValidation && (
							<FormHelperText className="error-text">
								Password should be more than 8 symbols
							</FormHelperText>
						)}
					</FormControl>
					{ errorMsg &&
						<Typography className='signin-error-text'>
							{errorMsg}
						</Typography>
					}
					<Button
						type="submit"
						variant="contained"
						color="primary"
						className="auth-form-button"
						disabled={!enabledSignInButton || loading}
					>
						{ loading ? <CircularProgress size={24} /> : 'Sign in' }
					</Button>
				</form>
				<div className="auth-link-wrapper">
					<Link to="/forgot-password" className="auth-link">
						Forgot Password
					</Link>
				</div>
			</div>
		</div>
	);
}

export default SignIn;