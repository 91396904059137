import React, { useState } from 'react'
import { Input, InputLabel } from '@mui/material'
import { googleAutocomplete, geocode } from '../../services/helper'
import './Place.css'

type Props = {
	onPress: (place: any) => any,
	placeholder?: string,
}

export const Place = ({ onPress, placeholder = 'Start typing address'}: Props) => {

	const [state, setState] = useState({
		inputVal: '',
		predictions: []
	})

	const handleAddressLookup = async (e: any) => {
		let val = e.target.value
		setState(current => ({ ...current, inputVal: val }))
		if (val.length > 3) {
			const predictions = await googleAutocomplete(val)
			console.log(predictions)
			if (predictions) {
				setState((current: any) => ({ ...current, predictions }))
			}
		}
	}

	const handleAddressSelection = async (place: any) => {
		const geocoded: any = await geocode(place.place_id)
		onPress(geocoded[0])
		setState(current => ({ ...current, inputVal: place.description, predictions: [] }))
	}

	return (
		<>
			<InputLabel>Address Lookup</InputLabel>
			<Input
				placeholder={ placeholder }
				onChange={ handleAddressLookup }
				value={state.inputVal}
			/>
			{ !!state.predictions.length && <div className="placesResultsContainer">
				{ state.predictions?.map((prediction: any) => (
					<p onClick={() => handleAddressSelection(prediction)} key={prediction?.place_id}>
						{prediction?.description}
					</p>
				)) }
			</div>}

		</>
	)
}
