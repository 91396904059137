import axios from 'axios'
import theme from '../config'
import { state } from 'venti'
import { userLogoutAction } from '../store/user'
const host = theme.api.host.production
const host2 = theme.api.host2.production

// If token is expired logout a user
axios.interceptors.request.use(
	async function(config) {
		const user = state.get(theme.storageKeys.user)
		if (user && new Date() > new Date(user.tokenExp)) {
			await userLogoutAction()
		}

		return config
	}, function (error) {
		return Promise.reject(error)
	}
)

const _setAuthHeader = (accessToken?: string) => ({ headers: { 'Authorization': `Bearer ${accessToken || state.get(theme.storageKeys.authToken)}` } })

/**
 * @param {string} userName
 * @param {string} password
 * @returns {Promise}
 */
export function authenticate(userName: string, password: string) {
	const urlencoded = new URLSearchParams()
	urlencoded.append("grant_type", "password")
	urlencoded.append("password", password)
	urlencoded.append("userName", userName)

	return post(
		`${host}/token`,
		urlencoded,
		_setAuthHeader()
	)
}

/**
 * Get authenticated user data
 * @returns {Promise}
 */
export function getMe(authToken: string) {
	return get(
		`${host}/api/users/userInfo`,
		_setAuthHeader(authToken)
	)
}

/**
 * Create a new loan
 * @param {object} data
 * @returns {Promise}
 */
export function createNewCustomer(data: any) {
	return post(
		`${host}/api/selfprovisioning/newcustomer`,
		data,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 */
export function getNewCustomers() {
	return get(
		`${host}/api/selfprovisioning/newcustomer`,
		_setAuthHeader()
	)
}

/**
 * @returns {Promise}
 * @param {string} sn
 */
export function getDeviceApps(sn: string ) {
	return get(`${host2}/devices/${sn}/apps`)
}

/**
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export function get(url: string, config = {}) {
	return axios.get(url, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function post(url: string, data: any, config = {}) {
	return axios.post(url, data, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function put(url: string, data: any, config= {}) {
	return axios.put(url, data, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} config
 * @returns {Promise}
 */
export function patch(url: string, data: any, config = {}) {
	return axios.patch(url, data, config).then(response => typeof response !== 'undefined' && response.data).catch(error => Promise.reject(error.response))
}

/**
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export function remove(url: string, config: any) {
	return axios.delete(url, config).then(response => response?.data).catch(error => Promise.reject(error.response.data))
}
