import { useState } from 'react'
import SearchBar from '../SearchBar/SearchBar'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
    TableSortLabel, Paper, Button
} from '@mui/material'
import './DataTable.css'
import {formatDate} from "../../services/helper";

type Props = {
    data: any
    headers: {
        id: string;
        name: string
    }[]
    fields: string[]
    onEditClient: (row: any) => void;
}

export const DataTable = (props: Props) => {
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [tableData, setTableData] = useState(props.data)
    const [sortAscending, setSortAscending] = useState<boolean>(true)
    const [sortedBy, setSortBy] = useState<string>('')
    const [searchText, setSearchText] = useState<string>('')

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const dataFilter = (row: any) => {
        if (!searchText) return true
        for (let i in props.fields) {
            if (row[props.fields[i]].includes(searchText)) return true
        }
        return false
    }


    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const sortData = (header: {id: string, name: string}) => {
        if (header.id !== sortedBy) setSortAscending(true)
        setSortAscending(!sortAscending)
        let sortedData = []
        if (header.id.includes('At')) {sortedData = tableData.sort((a: any, b: any) => {
                let startDate: any = new Date(a[header.id])
                let endDate: any = new Date(b[header.id])
                if (sortAscending) return startDate - endDate
                else return endDate - startDate
            }
        )} else {
            sortedData = tableData.sort((a: any, b: any) =>
                (sortAscending ? a[header.id] < b[header.id] : b[header.id] < a[header.id]) ? 1 : -1
            )}
        setTableData(sortedData)
        setSortBy(header.id)
    }

    const displayData = (value: any, field:string) => {
        if (field.slice(-2) === 'At') return formatDate(value)
        else if (field.slice(-4) === 'Date') value = formatDate(value, false)
        return value
    }

    return (
        <>
            <SearchBar style={{ marginBottom: 20 }} value={searchText} onChange={(text:string) => setSearchText(text)} />
                <Paper className="data-table--wrapper">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {props.headers.map((header: {id: string, name: string}, index: number) => (
                                        <TableCell key={index}>
                                            <TableSortLabel
                                                active={sortedBy === header.id}
                                                direction={sortedBy === header.id && sortAscending ? 'asc' : 'desc'}
                                                onClick={() => sortData(header)}
                                            >
                                                {header.name}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData
                                    .filter(dataFilter)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: any, index: number) => (
                                        <TableRow key={index} onClick={() => props?.onEditClient(row)}>
                                            {props.fields.map((field: string, index: number) => (
                                                <TableCell key={index} className="data-table--cell">
                                                    {displayData(row[field], field)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="data-table--pagination"
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={tableData.filter(dataFilter).length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
        </>
    )
}